import React, { useState } from 'react';
import ImgCity from "../Assets/city-line.png"
const Footer = () => {

    return (
        <>
            <div className='menuTop padding-topCstm1'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-4 col-sm-6 footerLinks'>
                            <h2>Quick Links</h2>
                            <ul>
                                <li>&rarr; Home</li>
                                <li>&rarr; About Us</li>
                                <li>&rarr; Who We Are</li>
                                <li>&rarr; What We Do</li>
                                <li>&rarr; Contact Us</li>
                            </ul>
                        </div>
                        <div className='col-md-4 col-sm-6 footerLinks'>
                            <h2>Our Products</h2>
                            <ul>
                                <li>&rarr; Acutok leistung</li>
                                <li>&rarr; Acutok glatt</li>
                                <li>&rarr; Acutok licht</li>
                                <li>&rarr; Acutok stark</li>
                                <li>&rarr; Acutok storm</li>
                            </ul>
                        </div>
                        <div className='col-md-4 col-sm-12 footerLinks'>
                            <h2>Connect With Us</h2>
                            <ul>
                                <li>Plot No. 36 & 37. Sr No. 101/1/1(P, Banglore Highway, near Mercedes Showroom, Baner, Mumbai, Pune, Maharashtra 411045</li>
                                    <li>&rarr; Email: info@acutok.com</li>
                                    <li>&rarr; Email: sales@acutok.com</li>
                            </ul>
                        </div>
                    </div>
                </div>
                
                <img src={ImgCity} className='cityImg' />
            </div>
            <div className='menuBottom'>
                <div className='col-12 footerBottom'>
                    <p>Copyright @2024 | All rights reserved</p>
                </div>
            </div>
        </>
    )
}
export default Footer