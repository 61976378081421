import React, { useState } from 'react';

import Logo from "../Assets/logo.png";
import Modal from './Modal';
const Menu = () => {
    const [isVisible, setIsVisible] = useState(false);
    const closeModalHandler=()=>{
        setIsVisible(!isVisible)
    }
    const [expanded, setExpanded] = useState(false);
    return (
        <>
            {isVisible &&
            <Modal closeModalHandler={closeModalHandler}></Modal>
}
            <div className="topMenu mobHide">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-3">
                            <img src={Logo} alt="banner" className="logo" />
                        </div>
                        <div className="col-6 menuDesktop">
                            <ul className="">
                                <li>stark</li>
                                <li>storm</li>
                                <li>leistung</li>
                                <li>glatt</li>
                                <li>licht</li>
                            </ul>
                        </div>
                        <div className="col-3">
                            <button className="bookNowBtn" onClick={()=>setIsVisible(!isVisible)}>Book Your Test Ride &rarr;</button>
                        </div>
                    </div>
                </div>
            </div>
            <nav class="navbar navbar-expand-lg navbar-dark bg-dark mobShow" expanded={expanded}>
                <a class="navbar-brand" href="#"><img src={Logo} alt="banner" className="logo" /></a>
                <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>

                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul class="navbar-nav mr-auto">
                        <li class="nav-item active">
                            <a class="nav-link" href="#">HOME <span class="sr-only">(current)</span></a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#">STARK</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#">STORM</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#">LEISTUNG</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#">GLATT</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#">LICHT</a>
                        </li>
                        <button className="bookNowBtn">Book Your Test Ride &rarr;</button>
                    </ul>
                </div>
            </nav>
            <button className="bookNowBtnStickey" onClick={()=>setIsVisible(!isVisible)}>Buy Now &rarr;</button>
        </>
    )
}
export default Menu