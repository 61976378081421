import React, { useState } from "react";
import Marquee from "react-fast-marquee";
import Banner from "../Assets/banner1.jpg";
import BannerMob from "../Assets/modal.jpg";
import Img1 from "../Assets/b3.jpg";
import Bike1 from "../Assets/bike1-home.JPG";
import Bike2 from "../Assets/bike2-home.JPG";
import Modal from "../components/Modal";
import P1 from '../Assets/homeImg1.jpg'
import P2 from '../Assets/homeImg2.jpg'
import Menu from "../components/Menu";
import Footer from "../components/Footer";
const Home = () => {
  const [isVisible, setIsVisible] = useState(false);
    const closeModalHandler=()=>{
        setIsVisible(!isVisible)
    }
  
  return (
    <div>
      <Menu></Menu>
      {isVisible &&
    <Modal closeModalHandler={closeModalHandler}></Modal>
}
      <div>
        <p className="notification">
          We’ve got some exciting offers exclusively for you. Upto Rs. 75000/- cashback. Terms & Conditions apply
        </p>
      </div>
      <div className="clearfix"></div>
      <div className="banner relative">
        <img src={Banner} alt="banner" className="BannerImg mobHide" />
        <img src={BannerMob} alt="banner" className="BannerImg mobShow" />
        <div className="bannerText mobHide">
          <h1 className="title">Ride Beyond Limits..</h1>
        </div>
        <div className="bottomGradient"></div>
      </div>
      <div className="clearfix"></div>

      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <span className="opText"></span>
            <h2 className="heading text-left">
              A New Identity For{" "}
              <span className="redColor">
                Electric
                <br />
                Motorcycles
              </span>
            </h2>
          </div>
          <div className="clearfix"></div>
          <div className="col">
            <div className="row">
              <div className="col-3 mobHide"></div>
              <div className="col-md-8 mobComPadd">
                <p className="text1">We are dedicated to redefining the future of mobility with a relentless focus on sustainability and innovation. Our efforts encompass not only the development of state-of-the-art EVs but also the establishment of robust support systems and infrastructure to enhance the overall EV experience. By pushing the boundaries of what is possible, we aim to set new benchmarks in the industry and lead the charge towards a cleaner, more efficient, and technologically advanced world.</p>
              </div>
            </div>

          </div>
        </div>
      </div>

      <div className="col-12 regPedding">
        <div className="row">
          <div className="col-md-3"></div>
          <div className="col-md-4">
            <img src={P1} alt="banner" className="BannerImg" />
          </div>
          <div className="col-md-4 mobHide">
            <img src={P2} alt="banner" className="BannerImg" />
          </div>
        </div>

      </div>
      <div className="container-fluid">

        <div className="row">
          <div className="col-12">
            <h2 className="heading text-center">
            Unleash the Future:<br/>Ride Electric,{" "}
              <span className="redColor">
              Ride Bold.
              </span>
            </h2>
          </div>
          <div className="clearfix"></div>
          <div className="col-md-6 daivik padding-topCstm1">
            <img src={Bike1} alt="banner" className="BannerImg" />
          </div>
          <div className="col-md-6 padding-topCstm1">
            <h2 className="heading text-left">
              ACUTOK <br />
              <span className="redColor">
                STARK
              </span>
            </h2>
            <ul className="specifications">
              <li>&rarr; Lithium Battery - 72V / 45 AMP</li>
              <li>&rarr; Range - 135+</li>
              <li>&rarr; 4 inch LCD display with IoT enabled 1 wire connectivity</li>
              <li>&rarr; Battery warranty - 3 years</li>
              <li>&rarr; Motor and controller warranty - 1 year</li>
              <li>&rarr; Charging time for 0-100% - 3-4 hours</li>
              <li>&rarr; Hydraulic suspension</li>
              <li>&rarr; Digital speedometer</li>
              <li>&rarr; Tubeless tyres and DRL light</li>
              <li>&rarr; LED lights and fast charging</li>
              <li>&rarr; Front/Rear Disc brakes</li>
            </ul>
            <button className="bookNowBtnHome mobPad20" onClick={()=>setIsVisible(!isVisible)}>Book Your Test Ride &rarr;</button>
          </div>
          <div className="clearfix"></div>
          <div className="col-md-6 daivik mobShow">
            <img src={Bike2} alt="banner" className="BannerImg" />
          </div>
          <div className="col-md-6 padding-topCstm1">
            <h2 className="heading text-left">
              ACUTOK <br />
              <span className="redColor">
                STORM
              </span>
            </h2>
            {/* <h4>SPECIFICATIONS</h4> */}
            <ul className="specifications">
              <li>&rarr; Lithium Battery - 72V / 45 AMP</li>
              <li>&rarr; Range - 135+</li>
              <li>&rarr; 4 inch LCD display with IoT enabled 1 wire connectivity</li>
              <li>&rarr; Battery warranty - 3 years</li>
              <li>&rarr; Motor and controller warranty - 1 year</li>
              <li>&rarr; Charging time for 0-100% - 3-4 hours</li>
              <li>&rarr; Hydraulic suspension</li>
              <li>&rarr; Digital speedometer</li>
              <li>&rarr; Tubeless tyres and DRL light</li>
              <li>&rarr; LED lights and fast charging</li>
              <li>&rarr; Front/Rear Disc brakes</li>
            </ul>

            <button className="bookNowBtnHome" onClick={()=>setIsVisible(!isVisible)}>Book Your Test Ride &rarr;</button>
          </div>
          <div className="col-md-6 daivik mobHide">
            <img src={Bike2} alt="banner" className="BannerImg" />
          </div>

        </div>
      </div>
      
      <div className="container-fluid">

        <div className="row">
          <div className="col-12  padding-topCstm1">
            <h2 className="heading text-center">
            Effortless Style,<br/>{" "}
              <span className="redColor">
              Electric Power.
              </span>
            </h2>
          </div>
          <div className="clearfix"></div>
          <div className="col-12 daivik">
            <img src={Img1} alt="banner" className="BannerImg" />
          </div>
          <div className="clearfix"></div>

        </div>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default Home;
