import React, { useState } from 'react';
import Img1 from '../Assets/modal.jpg'
import axios from 'axios';
const Modal = ({closeModalHandler}) => {
    const initialFormData = {
        Name: '',
        Email: '',
        Mobile: '',
        Pincode: '',
        City: '',
        Address: '',
        Message: ''
    };
    const [formData, setFormData] = useState(initialFormData);
    const [isVisible, setIsVisible] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });

    };

    const clearForm = (e) => {
        setFormData(initialFormData)
        setIsVisible(true);
        const timer = setTimeout(() => {
            setIsVisible(false);
        }, 3000);
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        const options = {
            method: 'POST',
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
            },
            data: formData,
            url: 'https://acutok.com/api/api/V001/contactus'
        }
        try {
            const response = await axios(options);
            console.log('Form data submitted successfully:', response.data);
            clearForm()
        } catch (error) {
            clearForm()
            console.error('Error submitting form data:', error);
        }
    };
    return (
        <div>
            <div className='modalFullWidth'>
                <div className='modalContainer'>
                    {isVisible && 
                    <div className='thanksForm'>
                    <p>Thank you for sharing your contact details with us. Our representative will be in touch with you shortly.</p>
                </div>
                    }
                
                    <div className='modalHalf mobHide'>
                        <img src={Img1} className="width100" />
                    </div>
                    <div className='modalHalf padding15'>
                        <span onClick={closeModalHandler} className='closeBtn'>X</span>
                        <h3 className='modalTitle'>BOOK A TEST RIDE</h3>
                        <p className='desFont'>We are open from Monday to Saturday between 10:00 AM to 6:00 PM.</p>
                        <form onSubmit={handleSubmit}>
                            <p className='formLabel'>Name *</p>
                            <input type="text" className='inputStyle' placeholder='Enter your name..' name='Name' value={formData.Name} onChange={handleChange}required />
                            <p className='formLabel'>Email Id *</p>
                            <input type="email" className='inputStyle' placeholder='Enter Email Id..' name='Email' value={formData.Email} onChange={handleChange} required/>
                            <p className='formLabel'>Contact Number *</p>
                            <input type="text" className='inputStyle' placeholder='Enter Contact number..' name='Mobile' value={formData.Mobile} onChange={handleChange}required />
                            <p className='formLabel'>PIN code</p>
                            <input type="text" className='inputStyle' placeholder='Enter PIN code..' name='Pincode' value={formData.Pincode} onChange={handleChange} />
                            <button type='submit' className='submitBtn'>SUBMIT</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Modal